import React from 'react'

const BackupImage = ({ className, category }) => {
  if (category) {
    return (
      <img
        className={className}
        src={`/img/category/${category}.jpg`}
      />
    )
  } else {
    return (<span>&nbsp;</span>)
  }
}

export default BackupImage
