import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import BackupImage from '../components/BackupImage'

const Selected = ({ selected }) => {
  const [changing, setChanging] = useState(false)

  useEffect(() => {
    setChanging(false)
    const timer = setTimeout(() => {
      setChanging(true)
    }, 100)
    return () => clearTimeout(timer)
  }, [selected])

  return (
    <div className="w-full">
      <div
        className={`selected__inner ${changing ? 'selected__inner_changing' : ''}`}
      >
        <div className='flex gap-8 items-center justify-center'>
          <div className='w-1/2 h-full'>
            {selected.quiltBlockFields.quiltImage ? (
              <GatsbyImage 
                loading="eager" 
                className="w-full h-full object-cover aspect-square" 
                image={selected.quiltBlockFields.quiltImage.gatsbyImage} 
                alt={selected.quiltBlockFields.quiltImage.altText}
              />
            ) : (
              <BackupImage className="w-full h-full object-cover aspect-square"
              category={selected.categories.nodes.length ? selected.categories.nodes[0].slug : 'agriculture'} />
            )}
          </div>
          <div className='w-1/2'>
            {selected.title !== "Empty" && (
              <h1
                className='text-5xl'
                dangerouslySetInnerHTML={{ __html: selected.title}}
              />
            )}
            <p className="text-gray-600 mb-5 text-3xl">
              {selected.quiltBlockFields.city && <span>{selected.quiltBlockFields.city}</span>}
              {selected.quiltBlockFields.state && selected.quiltBlockFields.city && <span>, </span>}
              {selected.quiltBlockFields.state && <span>{selected.quiltBlockFields.state}</span>}
              {selected.quiltBlockFields.state || selected.quiltBlockFields.city ? <span> | </span> : ''}
              {selected.quiltBlockFields.date ? (
                <span> {selected.quiltBlockFields.date}</span>
              ) : (
                <span> {selected.quiltBlockFields.century}</span>
              )}
            </p>
            <h4
              className='text-3xl'
              dangerouslySetInnerHTML={{ __html: selected.quiltBlockFields.description }}
            />
            <div className="text-lg my-5 md:mb-0 grid gap-2 grid-cols-tax">
              {selected.categories && selected.categories.nodes.length && (
                <h4>
                  <span className="font-bold uppercase tracking-widest">
                    Category:&nbsp;
                  </span>
                </h4>
              )}
              <h4 className="font-bold uppercase tracking-widest">
                {selected.categories && selected.categories.nodes.map(cat => (
                  <div className="flex items-center">
                    <span className="inline-flex"></span>{cat.name} 
                  </div>
                ))}
              </h4>
              {selected.tags && selected.tags.nodes.length && (
                <h4>
                  <span className="font-bold uppercase tracking-widest">
                    Tags:&nbsp;
                  </span>
                </h4>
              )}
              <h4>
                {selected.tags && selected.tags.nodes.map((tag, i) => (
                  <span className="lowercase">
                    {tag.name}
                    {selected.tags.nodes.length - 1 !== i && ','}{' '}
                  </span>
                ))}
              </h4>
            </div>
            {selected.quiltBlockFields.donor && (
              <p className="mt-5">
                <span className="font-bold">Adopted By</span>{' '}
                {selected.quiltBlockFields.donor}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CommunityQuilt = ({ data }) => {
  const quilt = data.allWpQuiltBlock.edges
  const [current, setCurrent] = useState(0)
  const [selected, setSelected] = useState(quilt[current].node)

  useEffect(() => {
    const startAdvancing = setInterval(() => {
        setCurrent(prevState => (prevState + 1) == quilt.length ? prevState = 0 : prevState + 1)
    }, 30000)
    return () => {
        clearInterval(startAdvancing)
    }
  }, [])

  useEffect(() => {
    setSelected(quilt[current].node)
  }, [current])

  return (
    <div className="w-screen h-screen bg-white p-20 overflow-hidden flex items-stretch">
      <Selected selected={selected} />
    </div>
  )
}

export const query = graphql`
  {
    allWpQuiltBlock(filter: { quiltBlockFields: { legacy: { ne: true } } }) {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          tags {
            nodes {
              name
              slug
            }
          }
          quiltBlockFields {
            century
            date
            city
            state
            description
            descriptionLong
            donor
            gender
            locationColumn
            locationRow
            legacy
            quiltImage {
              gatsbyImage(layout: CONSTRAINED, width: 1200, height: 1200)
              altText
            }
          }
        }
      }
      totalCount
    }
  }
`

export default CommunityQuilt
